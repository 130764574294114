import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressStatusEnum } from '../core';

@Component({
  selector: 'theia-cc-status-icon',
  standalone: true,
  template: `<ng-container [ngSwitch]="status">
    <svg
      [style.width.px]="size"
      [style.height.px]="size"
      *ngSwitchCase="ProgressStatusEnum.progress"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8376_46351)">
        <path d="M11.9168 0H10.0835V5.5H11.9168V0Z" fill="currentColor" />
        <g opacity="0.9">
          <path
            d="M18.1298 2.57384L14.2407 6.46289L15.5371 7.75924L19.4261 3.87019L18.1298 2.57384Z"
            fill="currentColor" />
        </g>
        <g opacity="0.8">
          <path d="M22 10.0833H16.5V11.9166H22V10.0833Z" fill="currentColor" />
        </g>
        <g opacity="0.7">
          <path
            d="M15.5371 14.241L14.2407 15.5374L18.1298 19.4264L19.4261 18.1301L15.5371 14.241Z"
            fill="currentColor" />
        </g>
        <g opacity="0.6">
          <path d="M11.9168 16.5H10.0835V22H11.9168V16.5Z" fill="currentColor" />
        </g>
        <g opacity="0.5">
          <path
            d="M6.46229 14.2408L2.57324 18.1299L3.86959 19.4262L7.75864 15.5372L6.46229 14.2408Z"
            fill="currentColor" />
        </g>
        <g opacity="0.4">
          <path d="M5.5 10.0833H0V11.9166H5.5V10.0833Z" fill="currentColor" />
        </g>
        <g opacity="0.3">
          <path
            d="M3.87008 2.57352L2.57373 3.86987L6.46278 7.75892L7.75913 6.46257L3.87008 2.57352Z"
            fill="currentColor" />
        </g>
      </g>
    </svg>
    <svg [style.width.px]="size" [style.height.px]="size" *ngSwitchDefault>
      <use [attr.xlink:href]="'assets/icons.svg#' + statusIcons[status]"></use>
    </svg>
  </ng-container>`,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule],
})
export class StatusIconComponent {
  @Input() status: ProgressStatusEnum = ProgressStatusEnum.none;
  @Input() size: number = 24;
  statusIcons = {
    [ProgressStatusEnum.done]: 'check',
    [ProgressStatusEnum.progress]: 'loading',
    [ProgressStatusEnum.none]: '',
  };
  protected readonly ProgressStatusEnum = ProgressStatusEnum;
}
