import { Component, Inject, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule, DOCUMENT, registerLocaleData } from '@angular/common';
import { HeaderComponent } from './blocks/header.component';
import { ContactComponent } from './blocks/contact.component';
import { StepsComponent } from './blocks/steps.component';
import { Data, DataService, Language, SageEnum } from './core';
import { ENVIRONMENT } from '@theia-cc/shared/helpers';
import {
  SanityImageService,
  SanityService,
  TransformSanityDataService,
} from '@theia-cc/shared/services';
import { environment } from '../environments/environment';
import localeIt from '@angular/common/locales/it-CH';
import localeDe from '@angular/common/locales/de-CH';
import localeFr from '@angular/common/locales/fr-CH';

@Component({
  standalone: true,
  selector: 'theia-cc-root',
  template: `<ng-container *ngIf="data; else spinner">
      <theia-cc-header
        [stage]="data?.stage"
        [clientAddress]="data?.clientAddress"
        [translations]="data?.translations"
        [logoImageUrl]="data?.logoImageUrl"
        [logoLinkUrl]="data?.logoLinkUrl"
        [headerImageUrl]="data?.heroHeaderImageUrl ?? 'assets/House.png'"></theia-cc-header>
      <theia-cc-steps
        *ngIf="data?.stage !== SageEnum.ABORT && data?.checklist?.length"
        [checklist]="data?.checklist"></theia-cc-steps>
      <section class="block-padding main-bg">
        <div class="container contact-container">
          <h2 class="h1" [innerText]="data?.translations['contact.title']"></h2>
          <div class="py-2">
            <theia-cc-contact [contactPersonData]="data?.customerRepInfo"></theia-cc-contact>
          </div>
          <div class="py-2" *ngIf="data?.projectContactInfo">
            <theia-cc-contact [contactPersonData]="data?.projectContactInfo"></theia-cc-contact>
          </div>
          <h5 [innerText]="data?.translations['contact.info']"></h5>
        </div>
      </section>
    </ng-container>
    <ng-template #spinner>
      <div class="spinner-wrapper">
        <div class="spinner"></div>
      </div>
    </ng-template>`,
  imports: [CommonModule, ContactComponent, HeaderComponent, StepsComponent],
  providers: [
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: LOCALE_ID,
      useFactory: () => `${getLangFromParams(new URLSearchParams(document.location.search))}-CH`,
    },
    SanityService,
    DataService,
    TransformSanityDataService,
    SanityImageService,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  protected readonly SageEnum = SageEnum;
  data: Data | void;

  constructor(private dataService: DataService, @Inject(DOCUMENT) private document: Document) {}

  public async ngOnInit() {
    registerLocaleData(localeDe, 'de-CH');
    registerLocaleData(localeFr, 'fr-CH');
    registerLocaleData(localeIt, 'it-CH');
    const searchParams = new URLSearchParams(this.document.location.search);
    const language = getLangFromParams(searchParams);
    const leadId = searchParams.get('leadId') || searchParams.get('lead');
    this.data = await this.dataService.getData(leadId, language as Language);
  }
}

export function getLangFromParams(searchParams: URLSearchParams) {
  return (
    searchParams.get('Language') || searchParams.get('language') || searchParams.get('lang') || 'de'
  );
}
