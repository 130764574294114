import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AccordionModule, StatusIconComponent } from '../components';
import { ProgressPhase } from '../core';

@Component({
  selector: 'theia-cc-steps',
  standalone: true,
  template: `<main
      class="container block-padding steps-container"
      [class.steps-container]="!isDatesOnly">
      <theia-cc-accordion [collapsing]="false" *ngIf="!isDatesOnly; else dateOnly">
        <theia-cc-accordion-item *ngFor="let phase of checklist" [expanded]="!!phase.status">
          <ng-template accordionTitle>
            <h3 class="phase-title" [class.completed]="!phase.status">{{ phase.title }}</h3>
          </ng-template>
          <ng-template accordionNavigation>
            <div class="flex-column align-items-center">
              <div class="status-round status-round__lg">
                <theia-cc-status-icon
                  class="hide-mobile"
                  [status]="phase.status"
                  [size]="36"></theia-cc-status-icon>
                <theia-cc-status-icon
                  class="show-mobile"
                  [status]="phase.status"
                  [size]="24"></theia-cc-status-icon>
              </div>
              <hr class="accordion__navigation-line" />
            </div>
          </ng-template>

          <ng-template accordionContent>
            <div class="phase-content">
              <ng-container *ngTemplateOutlet="dates; context: { phase }"></ng-container>
              <ng-container *ngFor="let step of phase.steps">
                <div class="d-flex align-items-center gap-1 pb-05">
                  <div class="status-round">
                    <theia-cc-status-icon [status]="step.status"></theia-cc-status-icon>
                  </div>
                  <p class="step-title p-m bold" [class.completed]="!step.status">
                    {{ step.title }}
                  </p>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </theia-cc-accordion-item>
      </theia-cc-accordion>
    </main>
    <ng-template #dateOnly>
      <div class="phase-content">
        <ng-container *ngFor="let phase of checklist">
          <ng-container *ngTemplateOutlet="dates; context: { phase }"></ng-container>
        </ng-container>
      </div>
    </ng-template>
    <ng-template #dates let-phase="phase">
      <h5 class="phase-date pb-1" *ngIf="phase.date">
        <span>{{ phase.dateLabel }}: </span>
        <span>
          {{ phase.date | date: 'dd. MMMM yyyy' }}
        </span>
        <span *ngIf="phase.endDate"> - {{ phase.endDate | date: 'dd. MMMM yyyy' }} </span>
      </h5>
    </ng-template> `,
  imports: [AccordionModule, StatusIconComponent],
  encapsulation: ViewEncapsulation.None,
})
export class StepsComponent implements OnChanges {
  @Input() checklist: ProgressPhase[];
  public isDatesOnly = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checklist) {
      this.isDatesOnly = this.checklist.every(phase => !phase.title);
    }
  }
}
