import { ThemeProperty } from '@theia-cc/shared/models';
export const THEME_TOKENS: ThemeProperty[] = [
  { property: 'textColor', cssVariable: '--text-color' },
  { property: 'mainBgColor', cssVariable: '--main-bg-color' },
  { property: 'checklistBgColor', cssVariable: '--checklist-bg-color' },
  { property: 'addressCardBgColor', cssVariable: '--address-card-bg-color' },
  { property: 'addressCardTextColor', cssVariable: '--address-card-text-color' },
  { property: 'stepsToggleButtonColor', cssVariable: '--steps-toggle-button-color' },
  { property: 'stepsNavLineColor', cssVariable: '--steps-nav-line-color' },
  { property: 'dateColor', cssVariable: '--date-color' },
  { property: 'statusIconColor', cssVariable: '--status-icon-color' },
  { property: 'statusIconBgColor', cssVariable: '--status-icon-bg-color' },
  { property: 'phaseTitleColor', cssVariable: '--phase-title-color' },
  { property: 'phaseCompletedTitleColor', cssVariable: '--phase-completed-title-color' },
  { property: 'stepTitleColor', cssVariable: '--step-title-color' },
  { property: 'stepCompletedTitleColor', cssVariable: '--step-completed-title-color' },
  { property: 'spinnerColor', cssVariable: '--spinner-color' },
];
